<template lang="pug">
div
  BaseListSearchOnly(
    :items="itemsSorted"
    searchLabel="search by decoration name"
    @search="setSearch"
    @select="select"
    @add="add"
  )
    template(slot-scope="{ item }")
      v-layout(row)
        v-flex(xs6) {{item.name}}
        v-flex(xs6) {{category(item)}}
    template(slot="title")
      | 
</template>

<script>
import { Decoration } from '@/models'
import { mapFields } from 'vuex-map-fields'
import BaseListSearchOnly from '@/components/base/list-search-only'
import LocalDetail from './detail'

export default {
  components: { BaseListSearchOnly, LocalDetail },
  computed: {
    ...mapFields('decorations', {
      items: 'items',
      item: 'item',
      search: 'search'
    }),
    ...mapFields('decorationCategories', {
      categories: 'items',
    }),
    itemsSorted () {
      // filter by search term
      let list = this.filterByProps(this.items, ['name'], this.search)
      // sort list by name
      return this.sortByString(list, 'name')
    },
    
  },
  methods: {
    async add () {
      this.item = new Decoration()
      const newDecoration = await this.$store.dispatch('decorations/postOne', { data: this.item })
      this.detail(newDecoration._id)
    },
    select (item) {
      this.detail(item._id)
    },
    detail (_id) {
      this.$router.push({ path: '/decorations/' + _id })
    },
    setSearch (search) {
      this.search = search
    },

    codes (item) {
      if (item.codes.length < 1) { return '' }
      return item.codes.map(code => {
        return code.code + ': ' + code.quantity
      })
    },

    category (item) {
      if (!item.decorationCategory) { return '' }
      const category = this.categories.find(x => x._id === item.decorationCategory)
      return category.name
    }
  }
}
</script>
