<template lang="pug">
v-container()
  FwdHeadingLarge decorations
  v-layout(row)
    v-flex
      Decorations
</template>

<script>
import Decorations from '@/modules/decorations/list'

export default {
  components: {
    Decorations
  }
}
</script>
